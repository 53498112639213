import React, { useContext, useEffect, useState } from "react";
import cartContext from "../context/cartContext";
import zuppaLogo from '../assets/images/ZuppaLogo.png';
import { Button } from "react-bootstrap";
import './Cart.css';
import productImage from "../assets/images/payment.jpg"; // Import your product image
import { FaTimes } from "react-icons/fa"; // Import close icon from react-icons library
import { useNavigate } from "react-router-dom";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


const Cart = () => {
  const {
    isCartOpen,
    cartItems,
    toggleCart,
    removeItem,
    incrementItem,
    decrementItem,
  } = useContext(cartContext);
  const [checkoutClicked, setCheckoutClicked] = useState(false); // State to track if checkout button is clicked
  const [imageClosed, setImageClosed] = useState(false); // State to track if the image is closed

  useEffect(() => {
    const docBody = document.body;

    isCartOpen
      ? docBody.classList.add("overflow_hide")
      : docBody.classList.remove("overflow_hide");
  }, [isCartOpen]);

  //Razor pay

  const [paymentStatus, setPaymentStatus] = useState("");
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const handlePayment = async () => {
    if (!scriptLoaded) {
      console.error("Razorpay script not loaded");
      return;
    }
 
    const options = {
      key: "rzp_live_LnGjL9h38GKF9g",
      amount: grandTotal*100, // Razorpay amount is in paisa
      currency: "INR",
      name: "Zuppa Geo Navigation Technology",
      description: "Purchase Description",
      image:zuppaLogo ,
      handler: function (response) {
        alert(response.razorpay_payment_id);
        setPaymentStatus("success");
      },
      prefill: {
        name: "Zuppa",
        email: "john.doe@example.com",
        contact: "9876543210",
      },
      notes: {
        address: "Some Address",
      },
      theme: {
        color: "#F37254",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };
const handleCheck = ()=>{
  const handleToken = localStorage.getItem("zuppa")
  console.log(handleToken)
  if (handleToken) {
    handlePayment()
  } else {
    alert("You Have to Login")
  window.location.href="/Login"
  }
}

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      setScriptLoaded(true);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const outsideClose = (e) => {
      if (e.target.id === "cart") {
        toggleCart(false);
      }
    };

    window.addEventListener("click", outsideClose);
    return () => {
      window.removeEventListener("click", outsideClose);
    };
  }, [toggleCart]);

  const cartQuantity = cartItems.length;

  const cartTotal = cartItems
    .map((item) => item.price * item.quantity)
    .reduce((prevValue, currValue) => prevValue + currValue, 0);

    const gst = Math.round(cartTotal * 0.18)
    console.log(gst, "hjsdasdkjashdkj");


    const grandTotal = cartTotal + gst;

  const handleCheckoutClick = () => {
    setCheckoutClicked(true);
    toggleCart(false); // Close the cart popup after checkout button is clicked
    toggleCart(!isCartOpen);
  };

  const handleCloseImage = () => {
    setImageClosed(true);
  };

  return (
    <>
      {isCartOpen && !checkoutClicked && (
        <div id="cart">
          <div className="cart_content">
            <div className="cart_head">
              <h2>
                Cart <small>({cartQuantity})</small>
              </h2>
              <div
                title="Close"
                className="close_btn"
                onClick={() => toggleCart(false)}
              >
            
                <span>&times;</span>
              </div>
            </div>

            <div className="cart_body">
              {cartQuantity === 0 ? (
          
                
                <img src="https://content.presentermedia.com/content/animsp/00007000/7277/stick_figure_shopping_cart_md_nwm_v2.gif" alt="Cart Is Empty"/>
             
                ) : (
                cartItems.map((item) => {
                  const { id, img, title, price, quantity } = item;
                  const itemTotal = price * quantity;

                  return (
                    <div className="cart_items" key={id}>
                      <figure className="cart_items_img">
                        <img src={img} alt="product-img" />
                      </figure>

                      <div className="cart_items_info">
                        <h5 style={{
                          color:"black",
                          backgroundColor:"white",
                          width:"180px"
                          }}>
                            {title}                           
                          </h5>
   
                        <h3 className="price">
                          ₹ {itemTotal.toLocaleString()}
                        </h3>
                      </div>

                      <div className="cart_items_quantity">
                        <span onClick={() => decrementItem(id)}><RemoveIcon   style={{color:"#b2102f"}}/></span>
                        <b>{quantity}</b>
                        <span onClick={() => incrementItem(id)}><AddIcon  color="success"/></span>
                      </div>

                      <div
                        title="Remove Item"
                        className="cart_items_delete"
                        onClick={() => removeItem(id)}
                      >
                            <DisabledByDefaultIcon  style={{color:"#b2102f"}} >
                        <span>&times;</span>
                        </DisabledByDefaultIcon>
                      </div>


                 
                    </div>
                  );
                })
              )}
            </div>

            <div className="cart_foot">
           <div className="cart_row">

           <h6 style={{ color: "red" }}>
            <small style={{ fontWeight:"bolder" }}>GST - 18 % : </small>
            <b>₹ {gst.toLocaleString()}</b>
            </h6>
           

           <h3 style={{ color: "black" }}>
                <small>Total:</small>
                <b>₹ {grandTotal.toLocaleString()}</b>
           </h3>
           </div>
              

              <div>
                <Button style={{ color: 'white', backgroundColor: 'green' }} onClick={handleCheck}>Pay Now</Button>
                {paymentStatus && <p>Payment Status: {paymentStatus}</p>}
              </div>
            </div>
          </div>
        </div>
      )}

      {!imageClosed && checkoutClicked && (
        <div className="overlay" onClick={handleCloseImage}>
          <div className="centered-image" style={{ position: "relative" }}>
            <img
              style={{ width: "500px", height: "600px" }}
              src={productImage}
              alt="product-img"
            />
            <div
              className="total-value"
              style={{
                position: "absolute",
                top: 250,
                right: 150,
                zIndex: 1,
                padding: "10px",
                backgroundColor: "goldenrod",
              }}
            >
              <h3 style={{ color: "Black" }}>
                <small>Total: </small>
                <b>₹ {cartTotal.toLocaleString()}</b>
              </h3>
            </div>
            <FaTimes className="close-icon" />
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
