
import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Button } from "react-bootstrap";
import { Zoom } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import "./Career.css";
import hiring from "../assets/images/hiring.gif";

function Career() {
  const navigate = useNavigate();

  const reviewMethod = () => {
    navigate("/Review");
  };

  const applyPost = () => {
    navigate("/CareerForm");
  };

  useEffect(() => {
    document.querySelector("#root").scrollIntoView({ behavior: "smooth" }, 500);
  }, []);

  return (
    <>
      <Header />
      <div className="container">
        {/* <h2 className="about-header text-center">Our Job Vacancies</h2> */}

        <div className="text-center">
          <img
            src={hiring}
            alt="Descriptive Alt Text"
            style={{ maxWidth: "250px", height: "auto", width: "auto" }}
          />
        </div>

        <div className="career-container">
          <br />
          <JobVacancy
            title="EMBEDDED DEVELOPER"
            experience="0 to 3 years"
            description="Job Description :"
            qualifications="BE [ ECE ],BSc /MSc [Electronics]"
            responsibilities={[
              "Experience in Embedded C programming.",
              "Experience in Arduino IDE.",
              "Experience in integrating different code bases and fixing integration issues.",
              "Knowledge in hardware and AVR and ARM processor architecture.",
              "Knowledge in GPIO, ADC, DAC and Timers",
              "Knowledge in I2C / UART/ SPI protocols",
              "Optional Knowledge on CAN",
            ]}
            onClickApply={applyPost}
          />
          <br />
          <JobVacancy
            title="MECHANICAL DESIGNER"
            experience="0 to 3 years"
            description="Job Description :"
            qualifications="BE [ MECH ]"
            responsibilities={[
              "Experience in Sheetmetal Design.",
              "Experience in Arduino Programming.",
              "Good skills in 3D concept development and 2D development drawings are a must.",
              "Understand the customer requirements and suggest alternative feasible concepts.",
              "Manufacturing knowledge of various manufacturing processes like sheet metal operations, welding & machining.",
              "Able to own complete product lifecycle (from concept to manufacturing) and able to interact with stakeholders to get signoff.",
              "Very good knowledge in reusability of functional similar parts between different machines.",
              "BOM preparation, manufacturing drawings & Drawing standard knowledge.",
              "Very good experience in Structural and Model analysis.",
              "Hands-on experience in SolidWorks.",
              "Knowledge in Engineering Change Order Process & expertise in mechanical hardware selection.",
              "Shall lead the team and drive the project.",
              "Should have very strong communication skills and team handling skills.",
            ]}
            onClickApply={applyPost}
          />
          <br />
          <JobVacancy
            title="SOFTWARE DEVELOPER"
            experience="0 to 3 years"
            description="Job Description :"
            qualifications="BE [CSE,ALL STREAMS]"
            responsibilities={[
              "Good development experience in developing applications using Java, Spring and Databases.",
              "Experience in Android development and debugging robust systems.",
              "Experience in multi-threaded programming and middleware solution development in an Android environment.",
              "Strong in fundamentals of IPC and OS concepts. Experience in Web Services technology including MAP server integration, XML, and HTTP, is a plus",
              "Experience in using development tools like Eclipse, IntelliJ.",
              "Ability to learn new technologies quickly. Strong understanding of product development life cycle.",
              "Able to understand and translate Specification/Requirements into the design and then to implementations.",
              "Knowledge of Application Servers and Databases.",
              "Good communication skills oral, written and presentation.",
              "Good experience in testing and test case development for Unit, Integration, Systems and Functionality Testing.",
            ]}
            onClickApply={applyPost}
          />
          <br />{" "}

          <JobVacancy
            title="Sr.ACCOUNTANT - TAXATION"
            experience="3 to 5 years"
            description="Job Description :"
            qualifications="B.com, M.com, CA-Inter, CA-Final"
            expectation="5-6 LPA"
            responsibilities={[
              "Good experience in GST.",
              "Experience in TDS.",
              "Experience in ITR.",
              "Good Knowledge in Taxation",
              "Experience in articleship in Auditor.",
              "Experience in Online Payroll-ZOHO.",
              "Good communication skills.",
         
         
            ]}
            onClickApply={applyPost}
          />
          <br/>

        </div>
      </div>

      <div className="employee-title">
        <Zoom right duration={1000}>
          <Button
            onClick={reviewMethod}
            className="employee-review"
            variant="info"
          >
            Employee Reviews
          </Button>
        </Zoom>
      </div>

      <Footer />
      <br />
    </>
  );
}

function JobVacancy({
  title,
  experience,
  description,
  qualifications,
  responsibilities,
  onClickApply,
}) {
  return (
    <div className="career-header">
      <div className="career-para">
        <h6>
          <b>{title}</b>
        </h6>
        <b>Experience: {experience}</b>
        <p>{description}</p>
        <ul className="unorder">
          {responsibilities.map((item, index) => (
            <li key={index} >{item}</li>
          ))}
        </ul>
        <p>
          <b>Qualification :</b> {qualifications}
        </p>
        <Button onClick={onClickApply} variant="primary">
          Apply
        </Button>
      </div>
    </div>
  );
}

export default Career;
